import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Logo from "../assets/images/cecilia.png";

const Header = styled.header`
  background-color: #220033;
  padding: 15px 20px;
  position: fixed;
  color: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    background-color 0.3s ease,
    padding 0.3s ease;

  &.scrolled {
    background-color: rgba(34, 0, 51, 0.9);
    padding: 10px 20px;
  }
`;

const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 1110px;
  justify-content: space-between;
  align-items: center;
  padding-right: 35px;
`;

const LogoImage = styled.img`
  max-height: 50px;
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #fff;
    width: 100%;
    max-width: 300px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    color: #220033;
    padding: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    display: none;

    a {
      color: #220033 !important;
    }
    &.open {
      display: flex;
    }
  }
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  position: relative;
  transition: color 0.3s ease;

  &.active {
    color: #fd7e14 !important;
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #fd7e14;
      position: absolute;
      bottom: -4px;
      left: 0;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    color: #fd7e14 !important;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Bar = styled.span`
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
`;

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const currentPath = window.location.pathname;

  return (
    <Header className={`${isScrolled ? "scrolled" : ""}`}>
      <NavbarContainer>
        <div className="logo">
          <a href="/">
            <LogoImage src={Logo} alt="Logo" />
          </a>
        </div>
        <NavLinks className={`${isMobileMenuOpen ? "open" : ""}`}>
          <NavLink href="/" className={currentPath === "/" ? "active" : ""}>
            Home
          </NavLink>
          <NavLink
            href="/quem-somos"
            className={currentPath === "/quem-somos" ? "active" : ""}
          >
            Quem somos nós
          </NavLink>
          <NavLink
            href="/projetos"
            className={currentPath === "/projetos" ? "active" : ""}
          >
            Nossos Projetos
          </NavLink>
          <NavLink
            href="/galeria"
            className={currentPath === "/galeria" ? "active" : ""}
          >
            Galeria de Fotos
          </NavLink>
          <NavLink
            href="/transparencia"
            className={currentPath === "/transparencia" ? "active" : ""}
          >
            Transparência
          </NavLink>
          <NavLink
            href="/contato"
            className={currentPath === "/contato" ? "active" : ""}
          >
            Contato
          </NavLink>
          <div>
            <NavLink
              href="/doacao"
              className="dtr-btn btn-grad-orange dtr-scroll-link"
            >
              Doe agora
            </NavLink>
          </div>
        </NavLinks>
        <Hamburger onClick={toggleMobileMenu}>
          <Bar />
          <Bar />
          <Bar />
        </Hamburger>
      </NavbarContainer>
    </Header>
  );
};

export default Navbar;
