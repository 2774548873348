import React from "react";
import styled from "styled-components";

const DonationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #fff4e3;
  margin-top: 75px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #220033;
  margin-bottom: 20px;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  color: #fd7e14;
  margin-bottom: 30px;
  text-align: center;
`;

const Card = styled.div`
  background-color: #ffffff;
  padding: 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  max-width: 500px;
  width: 100%;
`;

const InfoText = styled.p`
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
`;

const BankDetails = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  font-size: 1rem;
  color: #444;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #fd7e14;
`;

const CTAButton = styled.a`
  display: inline-block;
  padding: 15px 30px;
  background-color: #fd7e14;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-top: 20px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f57c00;
  }
`;

function Doacao() {
  return (
    <DonationContainer>
      <Title>Faça a Diferença com sua Doação</Title>
      <Subtitle>Transforme vidas através da educação e apoio social.</Subtitle>
      <Card>
        <InfoText>
          Contribua para nossos projetos e ajude a construir um futuro melhor.
          Todos os recursos arrecadados serão utilizados para ampliar e melhorar
          nossas ações em benefício da comunidade.
        </InfoText>
        <BankDetails>
          <p>
            <Highlight>Dados para Transferência:</Highlight>
          </p>
          <p>
            <Highlight>Agência:</Highlight> 0001
          </p>
          <p>
            <Highlight>Conta:</Highlight> 4191359-4
          </p>
          <p>
            <Highlight>Instituição:</Highlight> 403 - Cora SCD
          </p>
          <p>
            <Highlight>Nome:</Highlight> Instituto Cecília Meireles
          </p>
        </BankDetails>
        <CTAButton
          href="https://wa.me/5511949163131"
          target="_blank"
          rel="noopener noreferrer"
        >
          Quero contribuir
        </CTAButton>
      </Card>
    </DonationContainer>
  );
}

export default Doacao;
