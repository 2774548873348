import React from "react";
import styled from "styled-components";
import { FaFilePdf, FaExternalLinkAlt } from "react-icons/fa";

const TransparencyContainer = styled.div`
  padding: 60px 30px;
  background-color: #f9f9f9;
  text-align: center;
  font-family: "Arial", sans-serif;
  margin-top: 75px;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #220033;
  margin-bottom: 30px;
`;

const Subtitle = styled.p`
  font-size: 1.4rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

const TransparencyLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  padding: 15px 30px;
  background-color: #fd7e14;
  color: white;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: bold;
  border-radius: 8px;
  width: fit-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;

  &:hover {
    background-color: #f57c00;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
  }

  svg {
    margin-left: 10px;
  }
`;

const DocumentsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const DocumentCard = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const DocumentTitle = styled.h3`
  font-size: 1.2rem;
  color: #220033;
  margin-bottom: 15px;
`;

const DocumentDescription = styled.p`
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 20px;
`;

const DocumentButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-size: 1rem;
  background-color: #fd7e14;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #f57c00;
  }

  svg {
    margin-right: 10px;
  }
`;

function Transparencia() {
  return (
    <TransparencyContainer>
      <Title>Portal da Transparência</Title>
      <Subtitle>
        No <strong>Instituto Cecília Meireles</strong>, a transparência é uma
        prioridade. Aqui, você pode acessar informações detalhadas sobre como os
        recursos são utilizados para transformar vidas. Nosso compromisso é
        garantir que cada doação seja usada com responsabilidade e impacto.
      </Subtitle>

      <TransparencyLink
        href="http://transparencia.prefeitura.sp.gov.br/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Acesse o Portal da Transparência <FaExternalLinkAlt />
      </TransparencyLink>

      <DocumentsContainer>
        <DocumentCard>
          <DocumentTitle>Termo de Colaboração CIOESTE N◦001/2023</DocumentTitle>
          <DocumentDescription>
            Detalhes completos sobre os termos aplicados 2023.
          </DocumentDescription>
          <DocumentButton
            href="/assets/pdf/termo-colab-cioeste.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFilePdf /> Abrir PDF
          </DocumentButton>
        </DocumentCard>
        <DocumentCard>
          <DocumentTitle>
            Termo de Colaboração Casa Lar/Saica N◦001/2024
          </DocumentTitle>
          <DocumentDescription>
            Detalhes completos sobre os termos aplicados 2024.
          </DocumentDescription>
          <DocumentButton
            href="/assets/pdf/termo-colab-cioeste2.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFilePdf /> Abrir PDF
          </DocumentButton>
        </DocumentCard>
        <DocumentCard>
          <DocumentTitle>
            Termo de Colaboração Consórcio Intermunicipal Grande ABC N◦002/2023
          </DocumentTitle>
          <DocumentDescription>
            Detalhes completos sobre os termos aplicados 2023.
          </DocumentDescription>
          <DocumentButton
            href="/assets/pdf/termo-colab-cioeste3.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFilePdf /> Abrir PDF
          </DocumentButton>
        </DocumentCard>
      </DocumentsContainer>
    </TransparencyContainer>
  );
}

export default Transparencia;
